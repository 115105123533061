import React from 'react'
import Card from './components/Card'
import './App.css'

function App() {
  return (
   <div className="App">
     <Card/>

   </div>
       
  
  )
}

export default App