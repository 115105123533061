import React from 'react'
import imagen1 from '../assets/Logo-Yeapp.png'
import imagen2 from '../assets/linkedin.png'
import imagen3 from '../assets/facebook.png'

function Card() {
  return (
    <div className="card text-center bg-black pt-5">
      <img  class="card-img-top" src={imagen1} alt=""/>
      <div className="card-body">
          <h1 className="card-title text-white">TADHACK COLOMBIA 2022</h1>
          <p className="card-text text-secondary"> ¡No te puedes perder este evento de talla mundial,
           donde podrás participar con más de 1000 personas en 7 países y con premios de hasta 20K USD!</p>
           <p className="card-text text-secondary"> ¡CUPOS LIMITADOS!</p>
          <div class="d-flex flex-column bd-highlight mb-2">
          <a className="btn btn-light" href="https://tadhack.com/2022/global/register-colombia/">¡Registrate ya!</a> 
              
              <br>
              </br>
              
          <a className="btn btn-warning" href="https://blog.tadhack.com/2022/08/11/guide-to-tadhack-global-2022/">Guía para participar</a>

          </div>
          <br>
          </br>
          <div className="container ">
            <a  class="col-md-4 p-1" href="https://www.facebook.com/yeapp.io" ><img  class=".img-thumbnail" src={imagen3} alt="" width="40" height="40" /></a>
            <a  class="col-md-4 p-1" href="https://www.linkedin.com/company/yeappsas/mycompany/"><img  class=".img-thumbnail" src={imagen2} alt="" width="40" height="40"/></a>
          </div>
         
          
      </div>
    </div>
  )
}

export default Card